import React from "react";
import { ConfigProvider } from "antd";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Revenue from "./screens/Revenue";
import Orders from "./screens/Orders";
import LoginPage from "./screens/auth/LoginPage";
import { colors } from "./constants";

const App = () => (
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: colors.dark,
        borderRadius: 5,
      },
      components: {
        Table: {
          cellPaddingBlockSM: 1,
          cellPaddingInlineSM: 4,
          headerBorderRadius: 2,
          headerBg: "transparent",
        },
      },
    }}
  >
    <Router>
      <Routes>
        <Route exact path="/" element={<Orders />} />
        <Route exact path="/revenue" element={<Revenue />} />
        <Route exact path="/login" element={<LoginPage />} />
      </Routes>
    </Router>
  </ConfigProvider>
);

export default App;
