import React, { useEffect, useState } from "react";
import AppLayout from "./Layout";
import { Card, Table, Tag } from "antd";
import { getInfluencersMoney } from "../controllers";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "./style.css";

const Revenue = () => {
  const navigate = useNavigate();
  const [transactions, setTransactions] = useState([]);
  const [page, setpage] = useState(0);
  const [range, setRange] = useState([
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000),
    new Date(),
  ]);

  useEffect(() => {
    const auth = Cookies.get("auth");
    if (!auth) {
      return navigate("/login");
    }
    const authObj = JSON.parse(auth);

    getInfluencersMoney(authObj?._id, range, 50, page)
      .then((res) => {
        if (res.status === 200) {
          let list = [];
          res.data?.map((i, one) => list.push({ ...i, ...{ i: one } }));
          setTransactions(list);
        }
      })
      .catch((e) => console.log(e));
  }, [range]);

  const findColor = (order) => {
    let color = "blue";
    order.kind === "in" ? (color = "green") : (color = "orange");
    return color;
  };

  const columns = [
    {
      title: "SN",
      key: "sn",
      render: (_) => <p>{_.i + 1}</p>,
    },
    {
      title: "REFERENCE",
      key: "reference",
      render: (_, d) => <p>{d.ref}</p>,
    },

    {
      title: "AMOUNT",
      key: "amount",
      render: (_, d) => <p>TZS {d.amount.toLocaleString()}</p>,
    },
    {
      title: "DATE",
      key: "date",
      render: (_, d) => (
        <p>{new Date(d.createdAt).toDateString().toUpperCase()}</p>
      ),
    },
    {
      title: "KIND",
      dataIndex: "kind",
      key: "kind",
      render: (_, d) => (
        <>
          <Tag color={findColor(d)} key={_}>
            {d.kind.toUpperCase()}
          </Tag>
        </>
      ),
    },
  ];

  return (
    <AppLayout>
      <div style={{ margin: "20px 0" }}>
        <Card
          title="Transactions"
          // extra={<RangePicker onCalendarChange={(n, da) => setRange(da)} />}
        >
          <Table size="small" columns={columns} dataSource={transactions} />
        </Card>
      </div>
    </AppLayout>
  );
};

export default Revenue;
