import React, { useEffect, useState } from "react";
import AppLayout from "./Layout";
import { Card, Table, Tag } from "antd";
import { getOrdersBySameCoupon, getInfluencer } from "../controllers";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import "./style.css";

const Orders = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);
  const [page, setpage] = useState(0);

  useEffect(() => {
    const auth = Cookies.get("auth");
    if (!auth) {
      return navigate("/login");
    }
    const authObj = JSON.parse(auth);

    getInfluencer(authObj?._id)
      .then((resp) => {
        if (resp.data?.coupon) {
          getOrdersBySameCoupon(resp.data?.coupon, 50, page).then((res) => {
            if (res.status === 200) {
              let list = [];
              res.data?.map((i, one) => list.push({ ...i, ...{ i: one } }));
              setOrders(list);
            }
          });
        }
      })
      .catch((e) => console.log(e));
  }, []);

  const findColor = (order) => {
    let color = "blue";
    order.status === "delivered" ? (color = "green") : (color = "orange");

    return color;
  };

  const columns = [
    {
      render: (record) => (
        <React.Fragment>
          {record.i + 1}
          <br />
          {record.order_no}
          <br />
          {record.shop?.name}
          <br />
          {record.buyer.first_name}
          <br />
          {new Date(record.createdAt).toDateString()}
          <br />
          {<Tag color={findColor(record)}>{record.status.toUpperCase()}</Tag>}
        </React.Fragment>
      ),
      responsive: ["xs"],
    },
    {
      title: "SN",
      key: "sn",
      render: (_) => <p>{_.i + 1}</p>,
      responsive: ["sm"],
    },
    {
      title: "ORDER NO",
      key: "order_no",
      render: (_, d) => <p>{d.order_no}</p>,
      responsive: ["sm"],
    },

    {
      title: "STORE",
      key: "shop.name",
      render: (_, { shop }) => <p>{shop.name}</p>,
      responsive: ["sm"],
    },
    {
      title: "CUSTOMER",
      key: "buyer.first_name",
      render: (_, { buyer }) => (
        <p>
          {buyer.first_name} {buyer.last_name}
        </p>
      ),
      responsive: ["sm"],
    },
    {
      title: "DATE",
      key: "date",
      render: (_, d) => <p>{new Date(d.createdAt).toDateString()}</p>,
      responsive: ["sm"],
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (_, order) => (
        <>
          <Tag color={findColor(order)} key={_}>
            {order.status.toUpperCase()}
          </Tag>
        </>
      ),
      responsive: ["sm"],
    },
  ];

  return (
    <AppLayout>
      <div className="container">
        <Card
          title="Orders"
          // extra={<RangePicker onCalendarChange={(n, da) => setRange(da)} />}
        >
          <Table size="small" columns={columns} dataSource={orders} />
        </Card>
      </div>
    </AppLayout>
  );
};

export default Orders;
