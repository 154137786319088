import React, { useState, useEffect } from "react";
import { UserOutlined } from "@ant-design/icons";
import { Layout, Menu, Avatar, Card, message, Popconfirm, Button } from "antd";
import logo from "../../assets/imgs/white.png";
import { useNavigate } from "react-router-dom";
import { colors } from "../../constants";
import Cookies from "js-cookie";
import { getInfluencer, getCoupon } from "../../controllers";
import { createCoupon } from "../../controllers";
import "../style.css";

const { Header, Content, Footer } = Layout;

const AppLayout = ({ children }) => {
  const navigate = useNavigate();
  const [admin, setAdmin] = useState(null);
  const [merchant, setMerchant] = useState(null);
  const [signout, setSignOut] = useState(false);
  const [coupon, setCoupon] = useState(null);
  const [loading, setIsLoading] = useState(false);

  useEffect(() => {
    const auth = Cookies.get("auth");

    if (!auth) {
      return navigate("/login");
    }
    const authObj = JSON.parse(auth);
    if (!authObj.is_active) {
      return Cookies.remove("auth");
    }
    setAdmin(authObj);

    getInfluencer(authObj?._id)
      .then((resp) => {
        if (resp.status === 200) {
          setMerchant(resp.data);
        }
        if (resp.data?.coupon) {
          getCoupon(resp.data?.coupon)
            .then((re) => setCoupon(re.data))
            .catch();
        }
      })
      .catch((e) => console.log(e));
  }, [signout]);

  const confirm = (e) => {
    Cookies.remove("auth");
    message.success("Signed out");
    setSignOut(true);
  };
  const cancel = (e) => {
    console.log(e);
  };

  const checkCoponAvailability = (coupo) => {
    const givenDate = new Date(coupo?.expireDate);
    const currentDate = new Date();

    if (givenDate.getTime() > currentDate.getTime()) {
      return "pass";
    } else {
      return "expired";
    }
  };

  const updateCoupon = async () => {
    try {
      setIsLoading(true);
      await createCoupon(admin?._id);
      setIsLoading(false);
      return window.location.reload();
    } catch (error) {
      console.log({ error });
    }
  };

  const styles = {
    align: {
      display: "flex",
      alignItems: "baseline",
      padding: 0,
      margin: 0,
    },
    text: {
      padding: 0,
      marginLeft: 5,
      marginTop: 0,
      marginBottom: 0,
      fontWeight: "bold",
    },
    head: {
      fontWeight: "bold",
      fontSize: 11,
      opacity: 0.6,
      margin: 0,
    },
    val: {
      fontWeight: "bold",
      fontSize: 12,
      margin: 0,
    },
    coup: {
      fontWeight: "bold",
      fontSize: 18,
      marginLeft: 5,
      backgroundColor:
        checkCoponAvailability(coupon) === "pass" ? colors.green : "red",
      paddingLeft: 10,
      paddingRight: 10,
      borderRadius: 3,
      color: "white",
      marginTop: 18,
    },
  };

  const copyCode = () => {
    navigator.clipboard
      .writeText(coupon?.code)
      .then(() => {
        message.success("COPIED");
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      <Layout className="layout">
        <Header className="headerTop">
          <div
            className="demo-logo"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
            onClick={() => navigate("/")}
          >
            <img src={logo} style={{ height: 50 }} />
          </div>
          <Menu
            theme="dark"
            mode="horizontal"
            items={[
              { key: 1, label: "Orders" },
              { key: 3, label: "Revenue" },
            ]}
            style={{
              width: "20%",
              fontWeight: "bold",
              color: "white",
              alignItems: "center",
            }}
            onClick={(e) => {
              console.log({ e });
              if (e.key === "1") {
                return navigate("/");
              }
              if (e.key === "2") {
                return navigate("/droppers");
              }
              if (e.key === "3") {
                return navigate("/revenue");
              }
            }}
          />
          <Popconfirm
            title="Sign out"
            description="You are about to sign out"
            onConfirm={confirm}
            onCancel={cancel}
            okText="Sign out"
            cancelText="No"
          >
            <div className="adminName">
              <Avatar style={{ marginRight: 3 }} icon={<UserOutlined />} />
              {admin?.name}
            </div>

            <Avatar className="avata" icon={<UserOutlined />} />
          </Popconfirm>
        </Header>
        <Content className="contents">
          <Card
            title={
              <div>
                {coupon ? (
                  <>
                    {checkCoponAvailability(coupon) === "pass" ? (
                      <div onClick={copyCode} className="coupon">
                        <p style={styles.head}>COUPON: </p>
                        <p style={styles.coup}>{coupon.code.toUpperCase()}</p>
                      </div>
                    ) : (
                      <div className="coupon">
                        <p style={styles.head}>COUPON: </p>
                        <Button
                          type="primary"
                          onClick={updateCoupon}
                          loading={loading}
                        >
                          Create new coupon
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <div className="coupon">
                    <p style={styles.head}>COUPON: </p>
                    <Button
                      type="primary"
                      onClick={updateCoupon}
                      loading={loading}
                    >
                      Create new coupon
                    </Button>
                  </div>
                )}
              </div>
            }
            extra={
              <div className="expires">
                {coupon ? (
                  <div className="coupon">
                    <p style={styles.head}>EXPIRES: </p>
                    <p style={{ ...styles.head, marginLeft: 5 }}>
                      {new Date(coupon.expireDate).toDateString().toUpperCase()}
                    </p>
                  </div>
                ) : (
                  ""
                )}
              </div>
            }
          >
            <div className="topDetails">
              <div className="detail">
                <p style={styles.head}>EMAIL ADDRESS</p>
                <p style={styles.val}>{merchant?.email.toUpperCase()}</p>
              </div>
              <div className="detail">
                <p style={styles.head}>INFLUENCER NAME</p>
                <p style={styles.val}>{merchant?.name.toUpperCase()}</p>
              </div>
              <div className="detail">
                <p style={styles.head}>COUPON VALUE</p>
                <p style={styles.val}>
                  TZS {coupon?.amount_limit?.toLocaleString()}
                </p>
              </div>
            </div>
            <div className="topDetails">
              <div className="detail">
                <p style={styles.head}>INFLUENCER BANK</p>
                <p style={styles.val}>
                  {merchant?.bank_account?.provider?.toUpperCase()} -{" "}
                  {merchant?.bank_account?.account_number}
                </p>
              </div>
              <div className="detail">
                <p style={styles.head}>BALANCE</p>
                <p style={{ ...styles.val, color: "green" }}>
                  TZS {merchant?.balance?.toLocaleString()}
                </p>
              </div>
              <div className="detail">
                <p style={styles.head}>STATUS</p>
                <p
                  style={{
                    ...styles.val,
                    color: merchant?.is_active ? colors.green : "red",
                  }}
                >
                  {merchant?.is_active ? "ACTIVE" : "INACTIVE"}
                </p>
              </div>
            </div>
          </Card>
          {children}
        </Content>
        <Footer
          style={{
            textAlign: "center",
            backgroundColor: colors.dark,
            color: "white",
          }}
        >
          Msosidrop ©{new Date().getFullYear()}. Influencer Platform
        </Footer>
      </Layout>
    </>
  );
};

export default AppLayout;
