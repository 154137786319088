import { admin_api, coupon_api, order_api } from "./api";

export const getOrdersBySameCoupon = async (couponId, limit, page) => {
  try {
    const response = await order_api.get(
      `/order/coupon/${couponId}?page=${page}&limit=${limit}`,
      {
        validateStatus: () => true,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getInfluencersMoney = async (influencerId, range, limit, page) => {
  try {
    const response = await admin_api.get(
      `/money/influencer/${influencerId}?page=${page}&limit=${limit}&start=${range[0]}&end=${range[1]}`,
      {
        validateStatus: () => true,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const loginAdmin = async (body) => {
  try {
    const response = await admin_api.post(`/influencer/login/`, body, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getInfluencer = async (id) => {
  try {
    const response = await admin_api.get(`/influencer/${id}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getCoupon = async (id) => {
  try {
    const response = await coupon_api.get(`/coupon/${id}`, {
      validateStatus: () => true,
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const createCoupon = async (id) => {
  try {
    const response = await admin_api.put(
      `/influencer/update/coupon/${id}`,
      {},
      {
        validateStatus: () => true,
      }
    );
    return response;
  } catch (error) {
    console.log(error);
  }
};
